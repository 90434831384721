import mapboxgl from 'mapbox-gl';

const initMapbox = () => {
  const mapElement = document.getElementById('map');

  if (mapElement) {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10'
    });

    const markers = JSON.parse(mapElement.dataset.markers);
    const mapMarkers = [];
    markers.forEach((marker) => {
      const popup = new mapboxgl.Popup().setHTML(marker.infoWindow);
      // ******
      const element = document.createElement('div');
      element.className = 'marker-oui marker-non fas fa-map-marker-alt';
      element.style.backgroundSize = 'contain';
      element.style.width = '25px';
      element.style.height = '25px';
      // ******

      const newMarker = new mapboxgl.Marker(element)
        .setLngLat([ marker.lng, marker.lat ])
        .setPopup(popup)
        .addTo(map);
      mapMarkers.push(newMarker)
      // We use the "getElement" funtion provided by mapbox-gl to access to the marker's HTML an set an id
      newMarker.getElement().dataset.markerId = marker.id;
      // Put a microphone on the new marker listening for a mouseenter event

      newMarker.getElement().addEventListener('mouseenter', (e) => toggleCardHighlighting(e) );
      // We put a microphone on listening for a mouseleave event
      newMarker.getElement().addEventListener('mouseleave', (e) => toggleCardHighlighting(e) );
    });

    fitMapToMarkers(map, markers);
    openInfoWindow(mapMarkers);
  }
};

const fitMapToMarkers = (map, markers) => {
  const bounds = new mapboxgl.LngLatBounds();
  markers.forEach(marker => bounds.extend([ marker.lng, marker.lat ]));
  map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0});
};
const openInfoWindow = (markers) => {
  const cards = document.querySelectorAll('.card-index-map');
  cards.forEach((card, index) => {


    if (window.innerWidth >= 992) {
      card.addEventListener('mouseenter', () => {
          markers[index].togglePopup();
      });
      card.addEventListener('mouseleave', () => {
        markers[index].togglePopup();
      });
    }
  });
}

const toggleCardHighlighting = (event) => {
  // We select the card corresponding to the marker's id
  const card = document.querySelector(`[data-flat-id="${event.currentTarget.dataset.markerId}"]`);
  // Then we toggle the class "highlight" to the card
  card.classList.toggle('highlight');
}

export { initMapbox };
