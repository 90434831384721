const animationScroll = (anchor) => {
  const catchAnchor = document.querySelector(anchor)
  if (catchAnchor) {

    catchAnchor.addEventListener('click', function (e) {
          e.preventDefault();
          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
  }
}
const animationScrollAll = (anchor) => {
  const catchAnchors = document.querySelectorAll(anchor)
  catchAnchors.forEach((catchAnchor) => {
    if (catchAnchor) {

      catchAnchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    }

  })
}

export {animationScroll, animationScrollAll}
