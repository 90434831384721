// import 'images/calendar.png';
const images = require.context('../images', true)

import $ from 'jquery';
// global.$ = jQuery;
//= require jquery
//= require jquery_ujs

import 'bootstrap/dist/js/bootstrap';
import 'components/jquery_sticky';
import 'components/add_personne_form';
import 'components/datetimepicker';
import 'components/datetimepicker-fr';
import 'components/sethour';
import 'components/stripe_update.js';
import {animationScroll, animationScrollAll} from 'components/animation_scroll.js'
import { formHoliday } from 'components/formHoliday.js'
// import { initStripe_update } from '../components/stripe_update.js';
// import { initStripe } from '../components/stripe';
import { initMapbox } from '../plugins/init_mapbox';
// import { initAutocomplete } from '../plugins/init_autocomplete';
// import { initAutocomplete2 } from '../plugins/init_autocomplete';
import { initializeAutocomplete, load_google_place_api } from '../plugins/init_autocomplete';
import { inputDateTime } from '../components/inputDateTime.js';
// if (location.href.split("/")[location.href.split("/").length -1] + location.href.split("/")[location.href.split("/").length -2] === "newpayments") {
//   initStripe("pk_test_RIwY2NGGcc2zoBBzxknxCoin009tUzYUYD");
// };


// initAutocomplete2();
// initAutocomplete();
const dt = new Date();
         dt.setHours( dt.getHours() + 1 );
const et = new Date();
         et.setHours( et.getHours() + 2160 );
const admin_dt = new Date("jun 17, 2019 00:00:00");


$(".form_datetime").datetimepicker({
  format: "dd mm yyyy - hh:00",
  autoclose: true,
  todayBtn: false,
  language: 'fr',
  minView: 1,
  pickerPosition: "bottom-left",
  startDate: new Date(dt),
  endDate: new Date(et)
});

var form_admin_order_datetime = document.querySelector('.form_admin_order_datetime')
if (form_admin_order_datetime) {
  $(".form_admin_order_datetime").datetimepicker({
    format: "dd-mm-yyyy - hh:00",
    autoclose: true,
    todayBtn: true,
    language: 'fr',
    minView: 1,
    pickerPosition: "bottom-left",
    startDate: new Date(admin_dt),
  });
};
animationScroll(".price-summary-anchor");
animationScrollAll(".search-spot-show-anchor")
formHoliday();
initMapbox();
inputDateTime();
import 'stylesheets/application.scss';


initializeAutocomplete("shop_address")

