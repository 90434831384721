var form = document.getElementById('payment-form');
if (form) {
  var cardButton = document.getElementById('card-button');
  var clientSecret = cardButton.dataset.secret;

  var ApiKey = cardButton.dataset.stripekey
  var stripe = Stripe(ApiKey);
  var elements = stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    var cardElement = elements.create('card', {style: style});

    // Add an instance of the card Element into the `card-element` <div>.
    cardElement.mount('#card-element');
    var cardholderName = document.getElementById('cardholder-name');



    // Handle real-time validation errors from the card Element.
    cardElement.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {

        displayError.textContent = event.error.message;


      } else {
        displayError.textContent = '';
      }
    });


    form.addEventListener('submit', function(event) {
    event.preventDefault();
      stripe.handleCardPayment(
        clientSecret, cardElement, {
          payment_method_data: {
            billing_details: {name: cardholderName.value}
          }
        }
      ).then(function(result) {
        if (result.error) {

          var errorElement = document.getElementById('card-errors');
        } else {

           form.submit();
        }
      });
    });
  }
