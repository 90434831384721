
$(document).ready(function() {

    var width = $(window).width();
    if(width > "767"){
        $(".affixmodule").sticky({topSpacing:80,bottomSpacing:354,responsiveWidth:true});
    }
    $(".ajustement-plus").on("click",function(e){
       e.preventDefault();
       ajustement = $(".ajustement").val().split(" ")[0];
       if (ajustement == ""){
           ajustement=0;
       }
       ajustement = parseFloat(ajustement);
       ajustement+=1;
       $(".ajustement").val(ajustement + " bagage(s)");
    });
    $(".ajustement-moins").on("click",function(e){
       e.preventDefault();
       ajustement = $(".ajustement").val().split(" ")[0];
       if (ajustement == ""){
           ajustement=0;
       }
       if (ajustement > 1){
       ajustement = parseFloat(ajustement);
       ajustement-=1;
       $(".ajustement").val(ajustement + " bagage(s)");
       }
    });

});
// ajustement = parseInt(ajustement_1.split(" ")[0])
