const initializeAutocomplete = (id) => {

  var element = document.getElementById(id);
  if (element) {
    var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });
    google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);
  }
}

const onPlaceChanged = () => {
  var place = this.getPlace();

  // console.log(place);  // Uncomment this line to view the full object returned by Google API.

  for (var i in place.address_components) {
    var component = place.address_components[i];
    for (var j in component.types) {  // Some types are ["country", "political"]
      var type_element = document.getElementById(component.types[j]);
      if (type_element) {
        type_element.value = component.long_name;
      }
    }
  }
}

const load_google_place_api = () => {
  google.maps.event.addDomListener(window, 'load', function() {
    initializeAutocomplete('user_input_autocomplete_address');
  });

}

export { initializeAutocomplete, load_google_place_api }
// import places from 'places.js';

// const initAutocomplete = () => {
//   const addressInput = document.getElementById('shop_address');
//   if (addressInput) {
//     places({
//       appId: "",
//       apiKey: "",
//       container: addressInput });
//   }
// };

// const initAutocomplete2 = () => {
//   const addressInput = document.querySelector('.shop_address2');
//   if (addressInput) {
//     places({
//       appId: "",
//       apiKey: "",
//       container: addressInput });
//   }
// };


// export { initAutocomplete };
// export { initAutocomplete2 };
