const formHoliday = () => {
  const formHolidays = document.querySelectorAll(".form-holiday input")

  if (formHolidays.length > 0) {
    formHolidays.forEach((form) => {
      form.addEventListener('change', (event) => {
        const urlUpdate = event.currentTarget.dataset.url
        window.location = window.location.origin + urlUpdate
      })
    })

  }
}

export { formHoliday }
