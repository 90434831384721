const inputDateTime = () => {
  const inputs = document.querySelectorAll('.customeformedatetime');
  if (inputs) {

    inputs.forEach((input) => {
      const setValue = input.value
      input.addEventListener('blur',(event) => {

        const today = new Date();
        const date = today.getDate() + " " +(today.getMonth()+1)+ ' ' + today.getFullYear() + " - 09:00";
        // event.currentTarget.value = date
        event.currentTarget.value = setValue
        // event.currentTarget.value = "31 01 2022 - 09:00"
      })

    });


  }

}



export { inputDateTime }
